import "./styles.css";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import {
  //FiArrowRight,
  FiBell,
  // FiFrown,
  FiActivity,
  //FiMessageSquare,
  FiUsers,
  FiSearch,
  FiSend,
} from "react-icons/fi";
//import { MdPhoneAndroid } from "react-icons/md";
import { ImSpinner6 } from "react-icons/im";
import { Context } from "../../Context/Auth";
import Nivel1 from "./Niveis/Nivel1";
import Nivel2 from "./Niveis/Nivel2";
import Nivel3 from "./Niveis/Nivel3";

export default function HeaderDashboard() {
  const { logOut, userDetails } = useContext(Context);
  const tipoConta = userDetails ? userDetails.tipoConta : "";

  //console.log("Tipo de conta:", tipoConta);

  async function deslogar() {
    await logOut();
  }

  return (
    <div className="headerDashboard">
      <header>
        <ul>
          {tipoConta === "administrador" && <Nivel1 />}{" "}
          {tipoConta === "coordenação" && <Nivel2 />}
          {tipoConta === "sorteador" && <Nivel3 />}
        </ul>
      </header>

      <div>
        <button className="buttonLogOut" onClick={deslogar}>
          Desconectar
        </button>
      </div>
    </div>
  );
}
