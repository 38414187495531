import axios from "axios";

const apiKey = process.env.REACT_APP_KEY_X_API_KEY;
const baseURLLinkDoBem = process.env.REACT_APP_BASE_URL_PUSH;

//console.log(baseURLLinkDoBem);

const ApiPushLinkDoBem = axios.create({
  baseURL: baseURLLinkDoBem,
  headers: {
    "Content-Type": "application/json",
    "X-Api-Key": apiKey,
  },
});

export default ApiPushLinkDoBem;
