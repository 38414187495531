import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import ContextProvider from "../Context/Auth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Private from "./private";
import Error from "../pages/Error";
import Login from "../pages/Login";

import Painel from "../pages/Painel";
import Dash from "../pages/Dash/Dash";
import Depoimentos from "../pages/Depoimentos/Depoimentos";
import DadosComProblemas from "../pages/DadosComProblemas/DadosComProblemas";
import NovaVersaoApp from "../pages/NovaVersaoApp/NovaVersaoApp";
import BuscarPessoas from "../pages/BuscarPessoas/BuscarPessoas";
import PushByEstado from "../pages/Painel/PushByEstado";
import PushByPlatformAndState from "../pages/Painel/PushByPlatformAndState";
import PushByPlatform from "../pages/Painel/PushByPlatform";
import CadastrarConta from "../pages/CadastrarConta/CadastrarConta";
import BuscarPessoasParceiros from "../pages/BuscarPessoasParceiros/BuscarPessoasParceiros";
import DashParceiros from "../pages/DashParceiros/DashParceiros";
import BuscarPessoasAcesso3 from "../pages/BuscarPessoas/BuscarPessoasAcesso3";
import ConvidarParceiro from "../pages/ConvidarParceiro/ConvidarParceiro";
import Sorteio from "../pages/Sorteio";
import SorteadorGeral from "../pages/Sorteio/SorteadorGeral";

export default function RoutesApp() {
  return (
    <BrowserRouter>
      <ContextProvider>
        <ToastContainer outClose={3000} />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/notification-control-center"
            element={
              <Private>
                <Painel />
              </Private>
            }
          />
          <Route
            path="/push-by-estado"
            element={
              <Private>
                <PushByEstado />
              </Private>
            }
          />
          <Route
            path="/push-by-platform"
            element={
              <Private>
                <PushByPlatform />
              </Private>
            }
          />
          <Route
            path="/push-by-platform-and-state"
            element={
              <Private>
                <PushByPlatformAndState />
              </Private>
            }
          />
          <Route
            path="/dash"
            element={
              <Private>
                <Dash />
              </Private>
            }
          />
          <Route
            path="/dash-analise-parceiros"
            element={
              <Private>
                <DashParceiros />
              </Private>
            }
          />
          <Route
            path="/dash-parceiros"
            element={
              <Private>
                <BuscarPessoasParceiros />
              </Private>
            }
          />
          <Route
            path="/dash-nivel-de-acesso-3"
            element={
              <Private>
                <BuscarPessoasAcesso3 />
              </Private>
            }
          />
          <Route
            path="/cadastrar-conta"
            element={
              <Private>
                <CadastrarConta />
              </Private>
            }
          />
          <Route
            path="/convidar"
            element={
              <Private>
                <ConvidarParceiro />
              </Private>
            }
          />
          <Route
            path="/sorteio"
            element={
              <Private>
                <Sorteio />
              </Private>
            }
          />
          <Route
            path="/sorteio-geral"
            element={
              <Private>
                <SorteadorGeral />
              </Private>
            }
          />
          <Route
            path="/depoimentos"
            element={
              <Private>
                <Depoimentos />
              </Private>
            }
          />
          <Route
            path="/buscar-pessoas"
            element={
              <Private>
                <BuscarPessoas />
              </Private>
            }
          />
          <Route
            path="/dados-com-problemas"
            element={
              <Private>
                <DadosComProblemas />
              </Private>
            }
          />
          <Route
            path="/nova-versao-aplicativo"
            element={
              <Private>
                <NovaVersaoApp />
              </Private>
            }
          />
          <Route path="*" element={<Error />} />
        </Routes>
      </ContextProvider>
    </BrowserRouter>
  );
}
