import React, { useEffect, useState } from "react";
import "./style.css";

import { db } from "../../services/conexaoFirebase";
import { collection, getDocs, getDoc, doc } from "firebase/firestore";
import { copyToClipboard } from "@phntms/react-share";

import Logo from "../../assets/logo.png";
import ImgBolhaFundo from "../../assets/bolhaFundo.png";
import ImgBolhaFrente from "../../assets/bolhaFrente.png";
import { toast } from "react-toastify";

export default function SorteadorGeral() {
  const [lastWinners, setLastWinners] = useState([]);
  //  console.log(lastWinners);
  const [person, setPerson] = useState({ nome: "", uid: "" });
  const [loading, setLoading] = useState(false);

  const [categoria, setCategoria] = useState([]);
  const [categoriaSelecionada, setCategoriaSelecionada] = useState("");
  // console.log(categoriaSelecionada);
  const [pessoas, setPessoas] = useState([]);

  const [scroll, setScroll] = useState(0);

  const fetchPessoas = async (slug) => {
    if (!slug) {
      console.log("Slug não fornecido ou inválido.");
      return;
    }

    const campanhaDocRef = doc(db, "sorteioCampanhas", slug);
    const campanhaDocSnap = await getDoc(campanhaDocRef);

    if (campanhaDocSnap.exists()) {
      const participantes = campanhaDocSnap.data().participantes || [];
      const pessoas = participantes.map((participante) => ({
        name: participante.name,
        celular: participante.celular,
        email: participante.email,
        tituloForm: participante.tituloForm,
        chavePix: participante.chavePix,
        uid: participante.uid,
      }));
      setPessoas(pessoas);
    }
  };

  useEffect(() => {
    const fetchCategorias = async () => {
      const query = await getDocs(collection(db, "sorteioCampanhas"));
      const categorias = [];
      query.forEach((doc) => {
        categorias.push(doc.id);
      });
      setCategoria(categorias);
    };
    fetchCategorias();
  }, []);

  const handleSelectChange = (event) => {
    setCategoriaSelecionada("");
    setLastWinners([]);
    const slug = event.target.value;
    setCategoriaSelecionada(slug);
    fetchPessoas(slug);
  };

  const isNameInLastWinners = (name) => {
    return lastWinners.some((winner) => winner.nome === name);
  };

  const handleStart = () => {
    setLoading(true);

    if (pessoas.length > 0) {
      // Verifica se todos os participantes já foram sorteados
      if (lastWinners.length === pessoas.length) {
        alert("Todos os participantes já foram sorteados!");
        setLoading(false);
        return; // Sai da função se todos já foram sorteados
      }

      let currentIndex = 0;
      let lastWinnerIndex = null; // Variável para armazenar o índice do último vencedor
      const intervalId = setInterval(() => {
        let randomIndex;
        do {
          randomIndex = Math.floor(Math.random() * pessoas.length);
        } while (isNameInLastWinners(pessoas[randomIndex].name));

        setPerson({
          nome: pessoas[randomIndex].name,
          email: pessoas[randomIndex].email,
          celular: pessoas[randomIndex].celular,
          uid: pessoas[randomIndex].uid,
          chavePix: pessoas[randomIndex].chavePix,
        });

        setScroll(scroll + 400);

        setTimeout(() => {
          window.scrollTo({
            top: scroll,
            // behavior: "smooth",
          });
        }, 1000);

        currentIndex++;
        if (currentIndex === pessoas.length) {
          clearInterval(intervalId); // Para o sorteio
          lastWinnerIndex = randomIndex; // Armazena o índice do último vencedor
          setLastWinners((prevWinners) => [
            ...prevWinners,
            {
              position: prevWinners.length + 1,
              nome: pessoas[lastWinnerIndex].name,
              email: pessoas[lastWinnerIndex].email,
              celular: pessoas[lastWinnerIndex].celular,
              uid: pessoas[lastWinnerIndex].uid,
              chavePix: pessoas[randomIndex].chavePix,
            },
          ]);
          setLoading(false);

          if (lastWinners.length === pessoas.length) {
            alert("Todos os participantes foram sorteados!");
          }
        }
      }, 3); // Ajuste o intervalo de tempo conforme desejado
    }
  };

  // Função para formatar o email
  const formatEmail = (email) => {
    const [username, domain] = email.split("@");
    const maskedUsername = `${username.slice(0, 5)}***`;
    const maskedDomain = `***${domain.slice(domain.indexOf("."))}`;
    return `${maskedUsername}@${maskedDomain}`;
  };

  // Função para formatar o celular
  const formatPhone = (phone) => {
    const maskedPhone = `${phone.slice(0, 4)} 9****-${phone.slice(10)}`;
    return maskedPhone;
  };

  // Função para formatar o UID
  const formatUid = (uid) => {
    const maskedUid = `${uid.slice(0, 10)}******`;
    return maskedUid;
  };

  return (
    <div>
      <header className="headerSorteio">
        <div>
          <img src={Logo} alt="logo" />{" "}
        </div>
        <a href="/">Voltar</a>
      </header>
      <div className="areaContainer">
        <div style={{ marginTop: 20 }}>
          <select onChange={handleSelectChange}>
            {categoria.length <= 0 ? (
              <option>Buscando categorias...</option>
            ) : (
              <>
                <option value="">Selecione</option>
                {categoria.map((slug) => (
                  <option key={slug} value={slug}>
                    {slug}
                  </option>
                ))}
              </>
            )}
          </select>
        </div>

        <div className="areaSorteio">
          <div className="">
            {lastWinners.length <= 0 ? (
              <div className="areabannerSorteio">
                {categoriaSelecionada === "sorteio-iphone" && (
                  <img src="https://imgur.com/veDoUDM.png" alt="imagem" />
                )}
                {categoriaSelecionada === "sorteio-mercado-te-amo" && (
                  <img src="https://imgur.com/8KEmA3h.png" alt="imagem" />
                )}
                {categoriaSelecionada === "sorteio-maquina-de-lavar" && (
                  <img src="https://imgur.com/CLdEMM5.png" alt="imagem" />
                )}
                {categoriaSelecionada === "sorteio-fogao" && (
                  <img src="https://imgur.com/J3y7crj.png" alt="imagem" />
                )}
                {categoriaSelecionada ===
                  "geladeira-ou-mil-quinhentos-reais" && (
                  <img src="https://imgur.com/M2tFHU4.png" alt="imagem" />
                )}
                {categoriaSelecionada === "empanturrados" && (
                  <img src="https://imgur.com/FQOavmd.png" alt="imagem" />
                )}
                {categoriaSelecionada === "teamopedia" && (
                  <img src="https://imgur.com/0T0Tx6u.png" alt="imagem" />
                )}
              </div>
            ) : (
              <>
                <h2>
                  {lastWinners.length <= 0 ? (
                    <></>
                  ) : lastWinners.length === 1 ? (
                    "Ganhador!"
                  ) : (
                    "Ganhadores!"
                  )}
                </h2>
                {/* area ganhadores */}
                <div>
                  <ul>
                    {lastWinners.map((winner, index) => (
                      <li key={index}>
                        <div className="listaGanhadores">
                          <div
                            style={{
                              flexDirection: "row",
                              display: "flex",
                              gap: 15,
                            }}
                          >
                            <div className="boxCirculo">{winner.position}</div>
                            <div>
                              <span
                                style={{ fontWeight: "bold", fontSize: 22 }}
                              >
                                {winner.nome.split(" ")[0]}{" "}
                                {winner.nome.split(" ")[1]}
                              </span>
                              <p style={{ fontWeight: "bold", color: "#333" }}>
                                E-mail:{" "}
                                <span style={{ color: "#c4d30e" }}>
                                  {formatEmail(winner.email)}
                                </span>
                              </p>
                              <p style={{ fontWeight: "bold", color: "#333" }}>
                                Celular:{" "}
                                <span style={{ color: "#c4d30e" }}>
                                  {formatPhone(winner.celular)}
                                </span>
                              </p>
                              <p style={{ fontWeight: "bold", color: "#333" }}>
                                ID:{" "}
                                <span style={{ color: "#c4d30e" }}>
                                  {formatUid(winner.uid)}
                                </span>
                              </p>
                            </div>
                          </div>

                          <div style={{ display: "flex", gap: 10 }}>
                            <button
                              style={{
                                backgroundColor: "#c4d30e",
                                color: "#333",
                                fontWeight: "bold",
                                padding: 10,
                                border: "none",
                                borderRadius: 8,
                                marginTop: 20,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                copyToClipboard(winner.celular);
                                toast.success(
                                  "Conteúdo copiado para a área de transferência!"
                                );
                              }}
                            >
                              Copiar Telefone!
                            </button>
                            <button
                              style={{
                                backgroundColor: "#c4d30e",
                                color: "#333",
                                fontWeight: "bold",
                                padding: 10,
                                border: "none",
                                borderRadius: 8,
                                marginTop: 20,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                copyToClipboard(winner.email);
                                toast.success(
                                  "Conteúdo copiado para a área de transferência!"
                                );
                              }}
                            >
                              Copiar E-mail!
                            </button>
                            <button
                              style={{
                                backgroundColor: "#c4d30e",
                                color: "#333",
                                fontWeight: "bold",
                                padding: 10,
                                border: "none",
                                borderRadius: 8,
                                marginTop: 20,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                copyToClipboard(winner.chavePix);
                                toast.success(
                                  "Conteúdo copiado para a área de transferência!"
                                );
                              }}
                            >
                              Copiar Pix!
                            </button>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </>
            )}
          </div>

          <div className="containerBolhas">
            <div className="areaBolhas">
              <div className="areaNomesSorteio" style={{ position: "fixed" }}>
                {!loading && pessoas.length >= 1 && (
                  <>
                    <button class="pushable" onClick={handleStart}>
                      <span class="front">SORTEAR!</span>
                    </button>
                  </>
                )}

                {loading && (
                  <>
                    {lastWinners.length === pessoas.length ? (
                      <>
                        <p>Último ganhador</p>

                        <button class="pushable">
                          <span class="front">{person.nome.split(" ")[0]}</span>
                        </button>
                      </>
                    ) : (
                      <button class="pushable">
                        <span class="front">{person.nome.split(" ")[0]}</span>
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
