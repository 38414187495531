import { FaUser } from "react-icons/fa";

export default function DashGeral({
  userCount,
  usersParticipantes,
  usersBaixouApp,
  usuariosPorPromocao,
  usuariosPorEstado,
}) {
  return (
    <>
      <div className="coluna1">
        <div className="containerAnalises">
          <div className="boxAnalise">
            <div className="boxTitleEIcon">
              <FaUser size={20} color="#fff" />
              <p>Usuários cadastrados</p>
            </div>

            <div className="boxAnaliseCenter">
              {userCount ? (
                <span>{userCount}</span>
              ) : (
                <span style={{ fontSize: 15 }}>Carregando...</span>
              )}
            </div>
          </div>

          <div className="boxAnalise orangeBox">
            <div className="boxTitleEIcon">
              <FaUser size={20} color="#fff" />
              <p>Participam das promoções</p>
            </div>

            <div className="boxAnaliseCenter">
              {usersParticipantes ? (
                <span>{usersParticipantes}</span>
              ) : (
                <span style={{ fontSize: 15 }}>Carregando...</span>
              )}
            </div>
          </div>

          <div className="boxAnalise darkBox">
            <div className="boxTitleEIcon">
              <FaUser size={20} color="#fff" />
              <p>Baixaram o APP</p>
            </div>
            <div className="boxAnaliseCenter">
              {usersBaixouApp ? (
                <span>{usersBaixouApp}</span>
              ) : (
                <span style={{ fontSize: 15 }}>Carregando...</span>
              )}
            </div>
          </div>
        </div>

        <div className="rowSegundaLinha">
          <div className="boxAnaliseBig darkBox">
            <div className="boxTitleEIconBig">
              <FaUser size={20} color="#fff" />
              <p>Usuários por promoção</p>
            </div>

            <div className="boxAnaliseCenterBig">
              {Object.entries(usuariosPorPromocao)
                .sort(
                  ([tituloA, quantidadeA], [tituloB, quantidadeB]) =>
                    quantidadeB - quantidadeA
                )
                .map(([tituloForm, quantidade]) => (
                  <div key={tituloForm} className="boxItem">
                    <p>
                      {tituloForm} ({quantidade})
                    </p>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>

      <div className="coluna2">
        <div className="boxAnaliseBig blueBox">
          <div className="boxTitleEIconBig">
            <FaUser size={20} color="#fff" />
            <p>Usuários por Estado</p>
          </div>

          <div className="containerAreaEstados">
            {Object.entries(usuariosPorEstado)
              .sort(([estadoA], [estadoB]) => estadoA.localeCompare(estadoB))
              .map(([estado, quantidade]) => (
                <div key={estado} className="boxItem boxItemBlueDark">
                  <p>
                    {estado} ({quantidade})
                  </p>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}
