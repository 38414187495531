import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
//import { getAnalytics } from "firebase/analytics";

const firebaseConfigParceiros = {
  apiKey: "AIzaSyCVGcjQQpV1yQw1XOxzK_ahKI9PzGftQ-o",
  authDomain: "invite-partners.firebaseAppParceiros.com",
  projectId: "invite-partners",
  storageBucket: "invite-partners.appspot.com",
  messagingSenderId: "464805212639",
  appId: "1:464805212639:web:f9fe3efbf740331a26946a",
  measurementId: "G-MNNC9X37NP",
};

const firebaseAppParceiros = initializeApp(
  firebaseConfigParceiros,
  "invite-partners"
);
const authParceiros = getAuth(firebaseAppParceiros);
const dbParceiros = getFirestore(firebaseAppParceiros);
const storageParceiros = getStorage(firebaseAppParceiros);
//const analytics = getAnalytics(app);

export { authParceiros, dbParceiros, storageParceiros };
