import "./styles.css";
import React, { useState, useEffect } from "react";
import { auth, db, storage } from "../../services/conexaoFirebase";
import {
  collection,
  updateDoc,
  getDocs,
  query,
  where,
  limit,
  deleteDoc,
} from "firebase/firestore";
import { ref, deleteObject } from "firebase/storage";
import { toast } from "react-toastify";
import EstadosECidades from "../../Data/estados-e-cidades";
import HeaderDashboardParceiros from "../../components/HeaderDashboard/HeaderDashboardParceiros";

const BuscarPessoasAcesso3 = () => {
  const [usuarios, setUsuarios] = useState([]);
  const [totalParticipantes, setTotalParticipantes] = useState(0);

  const [loading, setLoading] = useState(false);
  const [participacoes, setParticipacoes] = useState([]);

  const [selectedStatus, setSelectedStatus] = useState("Sem etiqueta");
  const [selectedParticipacao, setSelectedParticipacao] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");

  const [citiesInSelectedState, setCitiesInSelectedState] = useState([]);
  const [totalUsuarios, setTotalUsuarios] = useState(0);
  const [limitUsers, setLimiteUsers] = useState(30);

  const [showFormComment, setShowFormComment] = useState(false);
  const [userObservacao, setUserObservacao] = useState("");

  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetchParticipacoes();
  }, []);

  useEffect(() => {
    if (selectedParticipacao || (selectedState && selectedCity)) {
      fetchUsuarios();
    }
  }, [
    selectedParticipacao,
    selectedState,
    selectedCity,
    selectedStatus,
    limitUsers,
  ]);

  const fetchParticipacoes = async () => {
    setLoading(true);
    const participacoes = [];
    const querySnapshot = await getDocs(collection(db, "users"));
    querySnapshot.forEach((doc) => {
      const user = doc.data();
      if (user.minhasParticipacoes) {
        Object.values(user.minhasParticipacoes).forEach((participacao) => {
          if (
            participacao.tituloForm &&
            !participacoes.includes(participacao.tituloForm)
          ) {
            participacoes.push(participacao.tituloForm);
          }
        });
      }
    });
    setParticipacoes(participacoes);
    setLoading(false);
  };

  const fetchUsuarios = async () => {
    setLoading(true);
    const usuariosFiltrados = [];
    const q = query(collection(db, "users"));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const user = doc.data();
      if (
        user.minhasParticipacoes &&
        (!selectedParticipacao ||
          user.minhasParticipacoes.some(
            (participacao) =>
              participacao.tituloForm === selectedParticipacao &&
              ((selectedStatus === "Todos" && true) ||
                (selectedStatus === "Sem etiqueta" &&
                  !["aprovado", "reprovado", "talvez"].includes(
                    participacao.status
                  )) ||
                participacao.status === selectedStatus)
          )) &&
        (!selectedState || user.estado === selectedState) &&
        (!selectedCity || user.cidade === selectedCity)
      ) {
        usuariosFiltrados.push(user);
      }
    });

    const usuariosLimitados = usuariosFiltrados.slice(0, limitUsers);
    setTotalUsuarios(usuariosFiltrados.length);
    setUsuarios(usuariosLimitados);
    setLoading(false);

    // Atualize o total de participantes da participação selecionada
    const participantes = usuariosFiltrados.filter((user) =>
      user.minhasParticipacoes.some(
        (participacao) => participacao.tituloForm === selectedParticipacao
      )
    ).length;
    setTotalParticipantes(participantes);
  };

  const handleStateChange = (e) => {
    const state = e.target.value;
    setSelectedState(state);
    setSelectedCity("");
    if (state) {
      const cities =
        EstadosECidades.estados.find((estado) => estado.sigla === state)
          ?.cidades || [];
      setCitiesInSelectedState(cities);
    } else {
      setCitiesInSelectedState([]);
    }
  };

  const handleCityChange = (e) => {
    const city = e.target.value;
    setSelectedCity(city);
  };

  const handleLoadMore = async () => {
    setLoading(true);
    // Incrementar o limite para buscar mais usuários
    setLimiteUsers((prevLimit) => prevLimit + 10);

    // Ajustar a consulta para buscar apenas os novos usuários com base no novo limite
    const newUsersSnapshot = await getDocs(
      query(collection(db, "users"), limit(limitUsers))
    );
    const newUsers = newUsersSnapshot.docs.map((doc) => doc.data());

    // Atualizar o estado dos usuários exibidos com os usuários antigos e novos
    setUsuarios([...usuarios, ...newUsers]);

    setLoading(false);
  };

  const handleAddObservacao = async (email, participacaoId) => {
    const confirmar = window.confirm(
      "Tem certeza que deseja adicionar esta observação?"
    );
    if (confirmar) {
      const userRef = collection(db, "users");
      const querySnapshot = await getDocs(
        query(userRef, where("email", "==", email))
      );
      querySnapshot.forEach(async (doc) => {
        const participacoes = doc.data().minhasParticipacoes.map((p) => {
          if (
            p.id === participacaoId &&
            p.tituloForm === selectedParticipacao
          ) {
            return { ...p, observacao: userObservacao };
          }
          return p;
        });
        await updateDoc(doc.ref, { minhasParticipacoes: participacoes });
      });
      alert("Observação adicionada com sucesso!");
      setUserObservacao(""); // Limpa o campo de observação após adicionar
    }
  };

  const handleAprovar = async (email, participacao) => {
    const confirmar = window.confirm(
      "Tem certeza que deseja aprovar este usuário?"
    );
    if (confirmar) {
      const userRef = collection(db, "users");
      const querySnapshot = await getDocs(
        query(userRef, where("email", "==", email))
      );
      querySnapshot.forEach(async (doc) => {
        // Atualizar o status na participação em vez do usuário
        const participacoes = doc.data().minhasParticipacoes.map((p) => {
          if (p.tituloForm === participacao.tituloForm) {
            return { ...p, status: "aprovado" };
          }
          return p;
        });
        await updateDoc(doc.ref, { minhasParticipacoes: participacoes });
      });
      alert("Usuário aprovado com sucesso!");
      setUsuarios((prevUsuarios) =>
        prevUsuarios.filter((user) => user.email !== email)
      );
    }
  };

  const handleTalvez = async (email, participacao) => {
    const confirmar = window.confirm(
      "Tem certeza que deseja reavaliar este usuário?"
    );
    if (confirmar) {
      const userRef = collection(db, "users");
      const querySnapshot = await getDocs(
        query(userRef, where("email", "==", email))
      );
      querySnapshot.forEach(async (doc) => {
        // Atualizar o status na participação em vez do usuário
        const participacoes = doc.data().minhasParticipacoes.map((p) => {
          if (p.tituloForm === participacao.tituloForm) {
            return { ...p, status: "talvez" };
          }
          return p;
        });
        await updateDoc(doc.ref, { minhasParticipacoes: participacoes });
      });
      alert("Usuário adicionado para avaliação com sucesso!");
      setUsuarios((prevUsuarios) =>
        prevUsuarios.filter((user) => user.email !== email)
      );
    }
  };

  const handleReprovar = async (email, participacao) => {
    const confirmar = window.confirm(
      "Tem certeza que deseja reprovar este usuário?"
    );
    if (confirmar) {
      const userRef = collection(db, "users");
      const querySnapshot = await getDocs(
        query(userRef, where("email", "==", email))
      );
      querySnapshot.forEach(async (doc) => {
        // Atualizar o status na participação em vez do usuário
        const participacoes = doc.data().minhasParticipacoes.map((p) => {
          if (p.tituloForm === participacao.tituloForm) {
            return { ...p, status: "reprovado" };
          }
          return p;
        });
        await updateDoc(doc.ref, { minhasParticipacoes: participacoes });
      });
      alert("Usuário reprovado com sucesso!");
      setUsuarios((prevUsuarios) =>
        prevUsuarios.filter((user) => user.email !== email)
      );
    }
  };

  const getUserByEmail = async (email) => {
    const userRef = collection(db, "users");
    const querySnapshot = await getDocs(
      query(userRef, where("email", "==", email))
    );
    return querySnapshot.docs.map((doc) => doc.data())[0]; // Retorna o primeiro documento encontrado
  };

  const handleApagarConta = async (email) => {
    const confirmar = window.confirm(
      "Tem certeza que deseja apagar a conta deste usuário? Esta ação é irreversível!"
    );
    if (confirmar) {
      try {
        // Buscar usuário pelo email
        const user = await getUserByEmail(email);

        if (!user) {
          throw new Error("Usuário não encontrado");
        }

        // Excluir usuário do Firebase Authentication
        const userRecord = await auth.getUserByEmail(email);
        await auth.deleteUser(userRecord.uid);

        // Excluir dados do Firestore
        const userRef = collection(db, "users");
        const querySnapshot = await getDocs(
          query(userRef, where("email", "==", email))
        );
        querySnapshot.forEach(async (doc) => {
          await deleteDoc(doc.ref);
        });

        // Excluir pasta correspondente no Firebase Storage
        const storageRef = ref(storage, `users/${user.id}`);
        await deleteObject(storageRef);

        alert("Conta excluída com sucesso!");
        fetchUsuarios(); // Atualizar a lista de usuários após a exclusão
      } catch (error) {
        console.error("Erro ao excluir conta:", error);
        alert("Erro ao excluir conta. Verifique o console para mais detalhes.");
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleSearch = async () => {
    if (searchTerm.trim() === "") {
      return;
    }

    setLoading(true);

    try {
      const q = query(collection(db, "users"));
      const querySnapshot = await getDocs(q);

      const foundUsers = [];
      querySnapshot.forEach((doc) => {
        const user = doc.data();
        if (
          user.minhasParticipacoes &&
          user.minhasParticipacoes.some(
            (participacao) =>
              participacao.tituloForm === selectedParticipacao &&
              (!selectedStatus ||
                (selectedStatus === "Todos" && true) ||
                (selectedStatus === "Sem etiqueta" &&
                  !["aprovado", "reprovado", "talvez"].includes(
                    participacao.status
                  )) ||
                participacao.status === selectedStatus)
          ) &&
          (!selectedState || user.estado === selectedState) &&
          (!selectedCity || user.cidade === selectedCity) &&
          removeAccents(user.name.toLowerCase()).includes(
            removeAccents(searchTerm.toLowerCase())
          )
        ) {
          foundUsers.push(user);
        }
      });

      if (foundUsers.length === 0) {
        toast.warn("Nenhum usuário encontrado com esse nome.");
      } else {
        setUsuarios(foundUsers);
        toast.success("Usuários encontrados com sucesso!");
      }
    } catch (error) {
      console.error("Erro ao buscar usuários:", error);
      toast.error("Erro ao buscar usuários. Por favor, tente novamente.");
    } finally {
      setLoading(false);
    }
  };

  const removeAccents = (str) => {
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/đ/g, "d")
      .replace(/Đ/g, "D");
  };

  return (
    <div className="containerAreaDashboard">
      <HeaderDashboardParceiros />
      <main className="contentDashbordDash">
        <div className="depoimentos">
          <h3>Usuários cadastrados</h3>

          {!loading && (
            <div
              style={{
                marginTop: 30,
                display: "flex",
                flexDirection: "row",
                gap: 15,
              }}
            >
              <div className="">
                <h5>Selecione uma campanha</h5>
                <select
                  className="selectFilterDepoimentos"
                  value={selectedParticipacao}
                  onChange={(e) => setSelectedParticipacao(e.target.value)}
                >
                  <option value="">Selecione uma campanha</option>
                  {participacoes
                    .filter(
                      (value, index, self) => self.indexOf(value) === index
                    )
                    .map((participacao, index) => (
                      <option key={index} value={participacao}>
                        {participacao}
                      </option>
                    ))}
                </select>
              </div>

              {selectedParticipacao && (
                <div>
                  <h5>Filtrar por Status</h5>
                  <select
                    className="selectFilterDepoimentos"
                    value={selectedStatus}
                    onChange={(e) => setSelectedStatus(e.target.value)}
                  >
                    <option value="Sem etiqueta">Sem etiqueta</option>
                    <option value="Todos">Todos</option>
                    <option value="aprovado">Aprovado</option>
                    <option value="reprovado">Reprovado</option>
                    <option value="talvez">Talvez</option>
                  </select>
                </div>
              )}

              {selectedParticipacao && (
                <div>
                  <h5>Buscar por Estado</h5>
                  <select
                    className="selectFilterDepoimentos"
                    value={selectedState}
                    onChange={handleStateChange}
                  >
                    <option value="">Selecione o Estado</option>
                    {EstadosECidades.estados.map((estado) => (
                      <option key={estado.sigla} value={estado.sigla}>
                        {estado.nome}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {selectedState && (
                <div>
                  <h5>Buscar por Cidade</h5>
                  <select
                    className="selectFilterDepoimentos"
                    value={selectedCity}
                    onChange={handleCityChange}
                  >
                    <option value="">Selecione a Cidade</option>
                    {citiesInSelectedState.map((city, index) => (
                      <option key={index} value={city}>
                        {city}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>
          )}

          {loading ? (
            <h3 style={{ paddingTop: 15, paddingBottom: 20 }}>
              Buscando dados...
            </h3>
          ) : usuarios.length === 0 ? (
            <h3 style={{ marginTop: 15 }}>Nenhum usuário encontrado!</h3>
          ) : (
            <div style={{ marginTop: 25 }}>
              <h3>{usuarios.length} Usuários encontrados inicialmente!</h3>
              {totalParticipantes >= 1 && (
                <span>
                  Total de usuários em {selectedParticipacao} (
                  {totalParticipantes}), {selectedState && `${selectedState}`}
                  {selectedCity && `/${selectedCity}`}
                </span>
              )}

              <div className="areaPesquisar">
                <input
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Pesquisar por nome"
                />
                <button onClick={handleSearch}>Buscar</button>
                <button
                  onClick={() => {
                    setSearchTerm("");
                    fetchUsuarios();
                  }}
                >
                  Limpar
                </button>
              </div>

              <div className="areaUsuarios">
                {usuarios
                  .sort((a, b) => {
                    if (a.id && b.id) {
                      return b.id.localeCompare(a.id);
                    } else {
                      return 0;
                    }
                  })
                  .map((user, index) => (
                    <div key={index} className="cardUsuario">
                      <p>
                        <strong>Nome: </strong> {user.name}
                      </p>
                      <p>
                        <strong>Celular: </strong>{" "}
                        <a
                          href={`https://wa.me/55${user.celular.replace(
                            /\D/g,
                            ""
                          )}`}
                          target="_blank"
                        >
                          {user.celular}
                        </a>
                      </p>
                      <p>
                        <strong>Email: </strong>
                        <a
                          href={`mailto:${user.email}?subject=Olá, somos do Rede Pública`}
                        >
                          {user.email}
                        </a>
                      </p>
                      <p>
                        <strong>Estado: </strong> {user.estado}
                      </p>
                      <p>
                        <strong>Cidade: </strong> {user.cidade}
                      </p>
                      <p>
                        <strong>CEP: </strong>{" "}
                        {user.cep ? user.cep : "CEP não cadastrado!"}
                      </p>

                      {user.minhasParticipacoes &&
                        user.minhasParticipacoes
                          .filter(
                            (participacao) =>
                              participacao.tituloForm === selectedParticipacao
                          )
                          .sort((a, b) => {
                            if (a.tituloForm === selectedParticipacao)
                              return -1;
                            if (b.tituloForm === selectedParticipacao) return 1;
                            return 0;
                          })
                          .map((participacao, index) => (
                            <div
                              key={index}
                              className="areaDivisaoParticipacoes"
                            >
                              {participacao.status && (
                                <p>
                                  <strong>Status: </strong>{" "}
                                  {participacao.status}
                                </p>
                              )}
                              <p>
                                <strong>Título da Participação: </strong>
                                {participacao.tituloForm}
                              </p>

                              {participacao.remedioDeUsoContinuo && (
                                <p>
                                  <strong>Remédio é contínuo?: </strong>
                                  {participacao.remedioDeUsoContinuo}
                                </p>
                              )}

                              {participacao.quantoTempoEstaoJuntos && (
                                <p>
                                  <strong>Quanto tempo estão juntos?: </strong>
                                  {participacao.quantoTempoEstaoJuntos}
                                </p>
                              )}

                              {participacao.quantosComodoTemSuaCasa && (
                                <p>
                                  <strong>Quantos cômodos a casa tem?: </strong>
                                  {participacao.quantosComodoTemSuaCasa}
                                </p>
                              )}

                              {participacao.dataPrevistaDoNascimento && (
                                <p>
                                  <strong>
                                    Data prevista para nascimento do bebê:{" "}
                                  </strong>
                                  {participacao.dataPrevistaDoNascimento}
                                </p>
                              )}
                              {participacao.meuPeso && (
                                <p>
                                  <strong>Meu peso: </strong>
                                  {participacao.meuPeso}
                                </p>
                              )}
                              {participacao.quantoTempoTrabalhaComIsso && (
                                <p>
                                  <strong>Trabalho com isso a: </strong>
                                  {participacao.quantoTempoTrabalhaComIsso}
                                </p>
                              )}
                              {participacao.acamadoOuPrecisaCadeiraDeRodas && (
                                <p>
                                  <strong>Informação sobre o idoso: </strong>
                                  {participacao.acamadoOuPrecisaCadeiraDeRodas}
                                </p>
                              )}
                              {participacao.selectedGeladeiraOuFogao && (
                                <p>
                                  <strong>Eu preciso de: </strong>
                                  {participacao.selectedGeladeiraOuFogao}
                                </p>
                              )}
                              {participacao.motivoVaquinha && (
                                <p>
                                  <strong>Motivo da vaquinha: </strong>
                                  {participacao.motivoVaquinha}
                                </p>
                              )}

                              {participacao.selectedTipoContrato && (
                                <p>
                                  <strong>Tipo de contrato: </strong>
                                  {participacao.selectedTipoContrato}
                                </p>
                              )}

                              {participacao.selectedTvOuMaquinaLavar && (
                                <p>
                                  <strong>Eu preciso de: </strong>
                                  {participacao.selectedTvOuMaquinaLavar}
                                </p>
                              )}

                              {participacao.selectedOquePrecisa && (
                                <p>
                                  <strong>Eu preciso de: </strong>
                                  {participacao.selectedOquePrecisa}
                                </p>
                              )}

                              {participacao.chavePix && (
                                <p>
                                  <strong>Chave pix: </strong>
                                  {participacao.chavePix}
                                </p>
                              )}

                              {participacao.foto1 || participacao.foto2 ? (
                                <>
                                  <div className="areaFotos">
                                    {participacao.foto1 && (
                                      <div className="boxFotoPromo">
                                        <p>Foto 1: </p>
                                        <a
                                          href={participacao.foto1}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <img
                                            src={participacao.foto1}
                                            alt="Foto 1"
                                            className="fotoMinhasParticipacoes"
                                          />
                                        </a>
                                      </div>
                                    )}
                                    {participacao.foto2 && (
                                      <div className="boxFotoPromo">
                                        <p>Foto 2: </p>
                                        <a
                                          href={participacao.foto2}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <img
                                            src={participacao.foto2}
                                            alt="Foto 2"
                                            className="fotoMinhasParticipacoes"
                                          />
                                        </a>
                                      </div>
                                    )}
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="boxFotoPromo">
                                    <p>Foto: </p>
                                  </div>
                                  <p>Essa campanha não precisa de foto</p>
                                </>
                              )}

                              <div className="areaDescricaoCaso">
                                <strong>Descrição do Caso: </strong>{" "}
                                <p>{participacao.textoCaso}</p>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <div className="areaBotoesAprovacoes">
                                  <button
                                    className="buttonAprovar"
                                    onClick={() =>
                                      handleAprovar(user.email, participacao)
                                    }
                                  >
                                    Aprovar
                                  </button>
                                  <button
                                    className="buttonTalvez"
                                    onClick={() =>
                                      handleTalvez(user.email, participacao)
                                    }
                                  >
                                    Talvez
                                  </button>
                                  <button
                                    className="buttonReprovar"
                                    onClick={() =>
                                      handleReprovar(user.email, participacao)
                                    }
                                  >
                                    Reprovar
                                  </button>
                                </div>
                              </div>

                              {showFormComment === user.email && (
                                <div className="areaObservacao">
                                  <textarea
                                    placeholder={`${
                                      participacao.observacao
                                        ? participacao.observacao
                                        : "Deixe uma observação"
                                    }`}
                                    value={userObservacao}
                                    onChange={(e) =>
                                      setUserObservacao(e.target.value)
                                    }
                                  ></textarea>
                                  <div>
                                    <button
                                      className="botaoSave"
                                      style={{ backgroundColor: "green" }}
                                      onClick={() =>
                                        handleAddObservacao(
                                          user.email,
                                          participacao.id
                                        )
                                      }
                                    >
                                      Adicionar e salvar!
                                    </button>
                                  </div>
                                </div>
                              )}

                              <div style={{ marginTop: 20 }}>
                                <button
                                  className="botaoShowForm"
                                  onClick={(e) =>
                                    setShowFormComment((prevId) =>
                                      prevId === user.email ? null : user.email
                                    )
                                  }
                                >
                                  {showFormComment === user.email
                                    ? "Fechar observação"
                                    : "Exibir observação"}
                                </button>
                              </div>
                            </div>
                          ))}
                    </div>
                  ))}
              </div>

              {usuarios.length < totalUsuarios && (
                <button onClick={handleLoadMore} className="botaoExibirMais">
                  Exibir mais
                </button>
              )}

              <div style={{ height: 100 }}></div>
            </div>
          )}
        </div>
      </main>
    </div>
  );
};

export default BuscarPessoasAcesso3;
