import React, { useState, useEffect } from "react";
import "./styles.css";
import HeaderDashboard from "../../components/HeaderDashboard/HeaderDashboard";
import { auth, db } from "../../services/conexaoFirebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { toast } from "react-toastify";
import {
  doc,
  setDoc,
  getDocs,
  getDoc,
  collection,
  updateDoc,
  query,
  where,
} from "firebase/firestore";

const CadastrarConta = () => {
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [nomeParceiro, setNomeParceiro] = useState("");
  const [categorias, setCategorias] = useState([]);
  const [tipoConta, setTipoConta] = useState("");
  const [loading, setLoading] = useState(false);
  const [participacoes, setParticipacoes] = useState([]);

  // checkbox selecionar todas as categorias
  const [selectAll, setSelectAll] = useState(false);

  const [selectAllUpdate, setSelectAllUpdate] = useState(false);
  const [categoriasUpdate, setCategoriasUpdate] = useState([]);

  const [selectedEmail, setSelectedEmail] = useState("");
  const [selectedUserCategorias, setSelectedUserCategorias] = useState([]);
  const [usersEmails, setUsersEmails] = useState([]);
  const [tipoContaUpdate, setTipoContaUpdate] = useState("");
  const [nomeParceiroView, setNomeParceiroView] = useState("");

  const fetchUsersEmails = async () => {
    setLoading(true);
    const emails = [];
    const querySnapshot = await getDocs(collection(db, "admins"));
    querySnapshot.forEach((doc) => {
      emails.push(doc.data().email);
    });
    setUsersEmails(emails);
    setLoading(false);
  };

  const fetchParticipacoes = async () => {
    setLoading(true);
    const participacoes = [];
    const querySnapshot = await getDocs(collection(db, "users"));
    querySnapshot.forEach((doc) => {
      const user = doc.data();
      if (user.minhasParticipacoes) {
        Object.values(user.minhasParticipacoes).forEach((participacao) => {
          if (
            participacao.tituloForm &&
            !participacoes.includes(participacao.tituloForm)
          ) {
            participacoes.push(participacao.tituloForm);
          }
        });
      }
    });
    setParticipacoes(participacoes);
    setLoading(false);
  };

  useEffect(() => {
    fetchUsersEmails();
    fetchParticipacoes();
  }, []);

  const handleCadastro = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Verificar se os campos estão preenchidos
    if (!email || !senha || !nomeParceiro || !categorias.length || !tipoConta) {
      setLoading(false);
      toast.error("Preencha todos os campos.");
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        senha
      );
      const user = userCredential.user;

      // Verificar as categorias existentes no Firestore
      const docRef = doc(db, "admins", user.uid);
      const docSnapshot = await getDoc(docRef);
      const existingCategorias = docSnapshot.exists()
        ? docSnapshot.data().categorias
        : [];

      // Filtrar as categorias para cadastrar apenas as que não existem ainda
      const categoriasParaCadastrar = categorias.filter(
        (categoria) => !existingCategorias.includes(categoria)
      );

      // Salvar os dados do usuário no Firestore
      await setDoc(doc(collection(db, "admins"), user.uid), {
        email,
        categorias: [...existingCategorias, ...categoriasParaCadastrar],
        tipoConta,
        uid: user.uid,
        nomeParceiro,
      });

      toast.success("Conta cadastrada com sucesso!");

      // Limpar os campos após o cadastro
      setEmail("");
      setSenha("");
      setCategorias([]);
      setTipoConta("");
      setNomeParceiro("");
    } catch (error) {
      console.error("Erro ao cadastrar conta:", error);

      // Tratar erros específicos, se possível
      if (error.code === "auth/email-already-in-use") {
        toast.error("Este email já está em uso.");
      } else {
        toast.error("Erro ao cadastrar conta. Por favor, tente novamente.");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSelectUser = async (e) => {
    const selectedEmailValue = e.target.value;
    setSelectedEmail(selectedEmailValue);

    const querySnapshot = await getDocs(
      query(collection(db, "admins"), where("email", "==", selectedEmailValue))
    );

    querySnapshot.forEach((doc) => {
      setSelectedUserCategorias(doc.data().categorias);
      const existingTipoConta = doc.data().tipoConta;
      const tipoContaExists = [
        "administrador",
        "coordenação",
        "social media",
        "reporte",
        "parceiro",
        "sorteador",
      ].includes(existingTipoConta);
      if (tipoContaExists) {
        setTipoContaUpdate(existingTipoConta);
      } else {
        setTipoContaUpdate("");
      }

      // Atualizar o nome do parceiro
      setNomeParceiroView(doc.data().nomeParceiro);
    });
  };

  const handleAddOrUpdateCategorias = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const querySnapshot = await getDocs(collection(db, "admins"));

      querySnapshot.forEach(async (doc) => {
        const userEmail = doc.data().email;

        if (userEmail === selectedEmail) {
          const docRef = doc.ref;
          await updateDoc(docRef, {
            categorias: categoriasUpdate,
          });
        }
      });

      toast.success("Categorias atualizadas com sucesso!");
      setSelectedEmail("");
      setSelectedUserCategorias([]);
      setCategorias([]);
    } catch (error) {
      console.error("Erro ao adicionar/atualizar categorias:", error);
      toast.error(
        "Erro ao adicionar/atualizar categorias. Por favor, tente novamente."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="containerAreaDashboard">
      <HeaderDashboard />
      <main className="contentDashbordDash">
        <div className="areaForm">
          <h3>Cadastrar parceiros/contas internas</h3>

          <form onSubmit={handleCadastro}>
            <div className="form-group">
              <strong>Nome/empresa parceira:</strong>
              <input
                type="text"
                className="form-control"
                value={nomeParceiro}
                onChange={(e) => setNomeParceiro(e.target.value)}
                required
                autoComplete="off"
                placeholder="Nome/empresa parceira"
              />
            </div>
            <div className="form-group">
              <strong>Email:</strong>
              <input
                type="email"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                autoComplete="off"
                placeholder="E-mail"
              />
            </div>
            <div className="form-group">
              <strong>Senha:</strong>
              <input
                type="text"
                className="form-control"
                value={senha}
                onChange={(e) => setSenha(e.target.value)}
                required
                placeholder="Senha"
                autoComplete="off"
              />
            </div>

            <div className="areaCategoria" style={{ marginBottom: 20 }}>
              <label
                style={{ marginBottom: 20, fontSize: 22, fontWeight: "bold" }}
              >
                Categorias:
              </label>
              <div>
                <div>
                  <label style={{ fontWeight: "bold" }}>
                    <input
                      style={{ marginRight: 5 }}
                      type="checkbox"
                      checked={selectAll}
                      onChange={(e) => {
                        setSelectAll(e.target.checked);
                        if (e.target.checked) {
                          setCategorias(
                            participacoes.filter(
                              (participacao) => participacao !== ""
                            )
                          );
                        } else {
                          setCategorias([]);
                        }
                      }}
                    />
                    Todas
                  </label>
                </div>
                {participacoes
                  .filter(
                    (value, index, self) =>
                      self.indexOf(value) === index && value !== ""
                  )
                  .map((participacao, index) => (
                    <label key={index} style={{ marginRight: 10 }}>
                      <input
                        style={{ marginRight: 5 }}
                        type="checkbox"
                        value={participacao}
                        checked={selectAll || categorias.includes(participacao)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setCategorias([...categorias, e.target.value]);
                          } else {
                            setCategorias(
                              categorias.filter(
                                (categoria) => categoria !== e.target.value
                              )
                            );
                          }
                        }}
                      />
                      {participacao}
                    </label>
                  ))}
              </div>
            </div>

            <div className="areaSelect">
              <strong>Tipo de Conta:</strong>
              <select
                className="form-control"
                value={tipoConta}
                onChange={(e) => setTipoConta(e.target.value)}
                required
              >
                <option value="">Selecione o tipo de conta</option>
                <option value="administrador">Administrador</option>
                <option value="coordenação">Coordenação</option>
                <option value="social media">Social Media</option>
                <option value="reporte">Reporte</option>
                <option value="parceiro">Parceiro</option>
                <option value="sorteador">Sorteador</option>
              </select>
              <button
                type="submit"
                style={{ fontWeight: "bold" }}
                disabled={loading}
              >
                Cadastrar
              </button>
            </div>
          </form>

          <div>
            <h3>Atualizar informações das contas</h3>
            <form onSubmit={handleCadastro}>
              <div className="areaSelect">
                <strong>Escolha um e-mail para atualizar:</strong>
                <select
                  className="form-control"
                  value={selectedEmail}
                  onChange={handleSelectUser}
                  required
                >
                  <option value="">Selecione um email</option>
                  {usersEmails.map((email, index) => (
                    <option key={index} value={email}>
                      {email}
                    </option>
                  ))}
                </select>
              </div>

              <div className="areaSelect">
                <strong>Tipo de Conta:*</strong>
                <select
                  className="form-control"
                  value={tipoContaUpdate}
                  onChange={(e) => setTipoContaUpdate(e.target.value)}
                  required
                >
                  <option value="">Selecione o tipo de conta</option>
                  <option value="administrador">Administrador</option>
                  <option value="coordenação">Coordenação</option>
                  <option value="social media">Social Media</option>
                  <option value="reporte">Reporte</option>
                  <option value="parceiro">Parceiro</option>
                  <option value="sorteador">Sorteador</option>
                </select>
                <span style={{ fontSize: 14, color: "red" }}>
                  *Deixe padrão caso não queira atualizar essa informação
                </span>
              </div>

              {selectedEmail && (
                <div className="areaCategoria">
                  <label
                    style={{
                      marginBottom: 20,
                      fontSize: 22,
                      fontWeight: "bold",
                    }}
                  >
                    Categorias de: {selectedEmail} | {nomeParceiroView}
                  </label>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 10,
                      width: "100%",
                      marginBottom: 30,
                      flexWrap: "wrap",
                    }}
                  >
                    {selectedUserCategorias.map((categoria, index) => (
                      <div
                        key={index}
                        style={{
                          backgroundColor: "#f1f1f1",
                          padding: 8,
                          borderRadius: 6,
                        }}
                      >
                        <span>{categoria}</span>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <div className="areaCategoria">
                <label
                  style={{ marginBottom: 20, fontSize: 22, fontWeight: "bold" }}
                >
                  Categorias a adicionar/atualizar:
                </label>
                <div>
                  <div>
                    <label style={{ fontWeight: "bold" }}>
                      <input
                        style={{ marginRight: 5 }}
                        type="checkbox"
                        checked={selectAllUpdate}
                        onChange={(e) => {
                          setSelectAllUpdate(e.target.checked);
                          if (e.target.checked) {
                            setCategoriasUpdate(
                              participacoes.filter(
                                (participacao) => participacao !== ""
                              )
                            );
                          } else {
                            setCategoriasUpdate([]);
                          }
                        }}
                      />
                      Todas
                    </label>
                  </div>
                  {participacoes
                    .filter(
                      (value, index, self) =>
                        self.indexOf(value) === index && value !== ""
                    )
                    .map((participacao, index) => (
                      <label key={index} style={{ marginRight: 10 }}>
                        <input
                          style={{ marginRight: 5 }}
                          type="checkbox"
                          value={participacao}
                          checked={categoriasUpdate.includes(participacao)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setCategoriasUpdate([
                                ...categoriasUpdate,
                                e.target.value,
                              ]);
                            } else {
                              setCategoriasUpdate(
                                categoriasUpdate.filter(
                                  (categoria) => categoria !== e.target.value
                                )
                              );
                            }
                          }}
                        />
                        {participacao}
                      </label>
                    ))}
                </div>
              </div>
              <div className="areaSelect">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={loading}
                  style={{ fontWeight: "bold" }}
                  onClick={handleAddOrUpdateCategorias}
                >
                  Adicionar/Atualizar Categorias
                </button>
              </div>
            </form>
          </div>
        </div>
      </main>
    </div>
  );
};

export default CadastrarConta;
