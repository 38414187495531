import React, { useContext, useState } from "react";
import "./style.css";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../../Context/Auth";
import HeaderDashboard from "../../components/HeaderDashboard/HeaderDashboard";

export default function ConvidarParceiro() {
  const { cadastrarParceiro } = useContext(Context);
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [nome, setNome] = useState("");

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (nome === "" || email === "" || password === "") {
      toast.error("Por favor, preencha todos os campos.");
      return;
    }

    setLoading(true);
    try {
      await cadastrarParceiro(email, password, nome);

      setLoading(false);
      //toast.success(`Parabéns você acaba de se cadastrar!`);
      setNome("");
      setEmail("");
      setPassword("");
    } catch (error) {
      console.log("Erro ao cadastrar: ", error);
      toast.error("Erro ao cadastrar. Por favor, tente novamente.");
      navigate("/");
      setLoading(false);
      // Se o erro for de email já em uso, não redirecione
      if (error.code !== "auth/email-already-in-use") {
        return;
      }
    }
  };

  const renderForm = () => {
    return (
      <div className="containerAreaDashboard">
        <HeaderDashboard />
        <main className="contentDashbordDash">
          <div className="">
            {/* Dados pessoais */}
            <div className="containerMultiForm containerMultiFormMarginTop">
              <h2 style={{ marginTop: 30, marginBottom: 30 }}>
                Cadastrar um convite!
              </h2>
              <div className="areaHeaderSteps">
                <h4 className="titlePageMultiForm">Dados pessoais</h4>
              </div>

              <div className="boxInput">
                <label>Nome completo:</label>
                <input
                  placeholder="Nome completo"
                  value={nome}
                  onChange={(event) => setNome(event.target.value)}
                  type="text"
                  title="Nome completo"
                />
              </div>
            </div>

            {/* Dados de acesso */}
            <div className="containerMultiForm containerMultiFormMarginTop">
              <div className="areaHeaderSteps">
                <h4 className="titlePageMultiForm">Dados de acesso</h4>
              </div>

              <div className="boxInput">
                <label>E-mail:</label>
                <input
                  placeholder="E-mail"
                  value={email}
                  required
                  onChange={(event) => setEmail(event.target.value)}
                  type="email"
                  title="E-mail"
                />
              </div>

              <div className="boxInput">
                <label>Crie uma senha:</label>
                <input
                  placeholder="********"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  type="text"
                  title="Crie uma senha"
                />
              </div>

              <div
                style={{
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  marginTop: 40,
                  flexDirection: "column",
                }}
              >
                <button
                  className="buttonMultiForm buttonMultiFormFinalize"
                  onClick={handleSubmit}
                >
                  {loading ? "Carregando..." : <>Cadastrar</>}
                </button>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  };

  return (
    <div>
      {renderForm()}

      <br />
      <br />
    </div>
  );
}
