import "./styles.css";
import React, { useContext, useState, useEffect } from "react";
import HeaderDashboard from "../../components/HeaderDashboard/HeaderDashboard";
import { Context } from "../../Context/Auth";
import { toast } from "react-toastify";
import { FaUser } from "react-icons/fa";

import { getUsersCount, db } from "../../services/conexaoFirebase";
import { collection, getDocs } from "firebase/firestore";
import DashGeral from "./DashGeral";
import Sorteio from "../Sorteio";

export default function Dash() {
  const { usuarios, userDetails } = useContext(Context);
  const tipoConta = userDetails ? userDetails.tipoConta : "";

  const [userCount, setUserCount] = useState(0);
  const [usersParticipantes, setUsersParticipantes] = useState(0);
  const [usersBaixouApp, setUsersBaixouApp] = useState(0);

  const [usuariosPorEstado, setUsuariosPorEstado] = useState({});
  const [usuariosPorPromocao, setUsuariosPorPromocao] = useState({});

  const [quantidadeErros, setQuantidadeErros] = useState(0);

  /*
  // Função para buscar a quantidade de usuários
  const fetchUserCount2 = async () => {
    try {
      const userRecords = await getUsersCount();
      const count = userRecords;
      setUserCount(count);
    } catch (error) {
      toast.error("Erro ao obter a quantidade de usuários:", error);
    }
  };
  */

  // quantidade de usuários que baixou o app
  const fetchUsersBaixouApp = () => {
    const usuariosQtd = usuarios.filter((user) => user.fcmToken).length;
    setUsersBaixouApp(usuariosQtd);
  };

  // Contar a qtd de usuarios por estado, é mais rápido
  const fetchUserCount = () => {
    const usersCount = usuarios.filter((user) => user.estado).length;
    setUserCount(usersCount);
  };

  // Função para buscar a quantidade de usuários que participa das promoções
  const fetchNumberOfUsersParticipating = () => {
    const usersCount = usuarios.filter(
      (user) => user.minhasParticipacoes
    ).length;
    setUsersParticipantes(usersCount);
  };

  // Função para contar usuários por estado
  const fetchUsuariosPorEstado = () => {
    const usersByState = usuarios.reduce((acc, user) => {
      const estado = user.estado;

      if (estado) {
        acc[estado] = (acc[estado] || 0) + 1;
      }

      return acc;
    }, {});

    setUsuariosPorEstado(usersByState);
  };

  // Função para buscar usuários por promoções
  const fetchUsuariosPorPromo = () => {
    const usersByPromo = usuarios.reduce((acc, user) => {
      if (user.minhasParticipacoes && user.minhasParticipacoes.length > 0) {
        user.minhasParticipacoes.forEach((participacao) => {
          const tituloForm = participacao.tituloForm;
          acc[tituloForm] = (acc[tituloForm] || 0) + 1;
        });
      }
      return acc;
    }, {});

    //console.log(usersByPromo);
    setUsuariosPorPromocao(usersByPromo);
  };

  // Função para buscar a quantidade de erros
  const fetchQuantidadeErros = async () => {
    try {
      const dadosComProblemasRef = collection(db, "dadosComProblemas");
      const snapshot = await getDocs(dadosComProblemasRef);

      // Obtém a quantidade de documentos na coleção
      const quantidadeDocumentos = snapshot.size;

      setQuantidadeErros(quantidadeDocumentos);
    } catch (error) {
      console.error("Erro ao obter a quantidade de erros:", error);
    }
  };

  useEffect(() => {
    fetchUserCount();
    fetchNumberOfUsersParticipating();
    fetchUsuariosPorEstado();
    fetchQuantidadeErros();
    fetchUsuariosPorPromo();
    fetchUsersBaixouApp();
  }, [usuarios]);

  return (
    <div className="containerAreaDashboard">
      <HeaderDashboard />
      <main className="contentDashbord">
        {tipoConta === "administrador" && (
          <DashGeral
            userCount={userCount}
            usersParticipantes={usersParticipantes}
            usersBaixouApp={usersBaixouApp}
            usuariosPorPromocao={usuariosPorPromocao}
            usuariosPorEstado={usuariosPorEstado}
          />
        )}

        {tipoConta === "coordenação" && (
          <DashGeral
            userCount={userCount}
            usersParticipantes={usersParticipantes}
            usersBaixouApp={usersBaixouApp}
            usuariosPorPromocao={usuariosPorPromocao}
            usuariosPorEstado={usuariosPorEstado}
          />
        )}

        {tipoConta === "sorteador" && (
          <div>
            <h3>Seja bem-vindo(a)!</h3>
            <p>{userDetails.nomeParceiro}</p>
          </div>
        )}
      </main>
    </div>
  );
}
