import "./styles.css";
import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../Context/Auth";
import { toast } from "react-toastify";
import { FaUser } from "react-icons/fa";

import { getUsersCount, db } from "../../services/conexaoFirebase";
import { collection, getDocs } from "firebase/firestore";
import HeaderDashboardParceiros from "../../components/HeaderDashboard/HeaderDashboardParceiros";

export default function DashParceiros() {
  const { usuarios, usuario } = useContext(Context);
  const [userCount, setUserCount] = useState(0);
  const [usersParticipantes, setUsersParticipantes] = useState(0);

  const [usuariosPorEstado, setUsuariosPorEstado] = useState({});
  const [usuariosPorPromocao, setUsuariosPorPromocao] = useState({});

  const [quantidadeErros, setQuantidadeErros] = useState(0);

  // Função para buscar a quantidade de usuários
  const fetchUserCount = async () => {
    try {
      const userRecords = await getUsersCount();
      const count = userRecords;
      setUserCount(count);
    } catch (error) {
      toast.error("Erro ao obter a quantidade de usuários:", error);
    }
  };

  // Função para buscar a quantidade de usuários que participa das promoções
  const fetchNumberOfUsersParticipating = () => {
    const usersCount = usuarios.filter(
      (user) => user.minhasParticipacoes
    ).length;
    setUsersParticipantes(usersCount);
  };

  // Função para contar usuários por estado
  const fetchUsuariosPorEstado = () => {
    const usersByState = usuarios.reduce((acc, user) => {
      const estado = user.estado;

      if (estado) {
        acc[estado] = (acc[estado] || 0) + 1;
      }

      return acc;
    }, {});

    setUsuariosPorEstado(usersByState);
  };

  // Função para buscar usuários por promoções
  const fetchUsuariosPorPromo = () => {
    const usersByPromo = usuarios.reduce((acc, user) => {
      if (user.minhasParticipacoes && user.minhasParticipacoes.length > 0) {
        user.minhasParticipacoes.forEach((participacao) => {
          const tituloForm = participacao.tituloForm;
          acc[tituloForm] = (acc[tituloForm] || 0) + 1;
        });
      }
      return acc;
    }, {});

    //console.log(usersByPromo);
    setUsuariosPorPromocao(usersByPromo);
  };

  // Função para buscar a quantidade de erros
  const fetchQuantidadeErros = async () => {
    try {
      const dadosComProblemasRef = collection(db, "dadosComProblemas");
      const snapshot = await getDocs(dadosComProblemasRef);

      // Obtém a quantidade de documentos na coleção
      const quantidadeDocumentos = snapshot.size;

      setQuantidadeErros(quantidadeDocumentos);
    } catch (error) {
      console.error("Erro ao obter a quantidade de erros:", error);
    }
  };

  useEffect(() => {
    fetchUserCount();
    fetchNumberOfUsersParticipating();
    fetchUsuariosPorEstado();
    fetchQuantidadeErros();
    fetchUsuariosPorPromo();
  }, [usuarios]);

  const usuariosFiltrados = usuarios.filter((user) =>
    usuario && usuario.categorias
      ? usuario.categorias.includes(user.categoria)
      : false
  );

  return (
    <div className="containerAreaDashboard">
      <HeaderDashboardParceiros />
      <main className="contentDashbord">
        <div className="coluna1">
          <div className="containerAnalises">
            <div className="boxAnalise">
              <div className="boxTitleEIcon">
                <FaUser size={20} color="#fff" />
                <p>Usuários cadastrados</p>
              </div>

              <div className="boxAnaliseCenter">
                <span>{userCount}</span>
              </div>
            </div>

            <div className="boxAnalise darkBox">
              <div className="boxTitleEIcon">
                <FaUser size={20} color="#fff" />
                <p>Em breve!</p>
              </div>
              <div className="boxAnaliseCenter">
                <span>...</span>
              </div>
            </div>

            <div className="boxAnalise darkBox">
              <div className="boxTitleEIcon">
                <FaUser size={20} color="#fff" />
                <p>Em breve!</p>
              </div>
              <div className="boxAnaliseCenter">
                <span>...</span>
              </div>
            </div>
          </div>

          <div className="rowSegundaLinha">
            <div className="boxAnaliseBig darkBox">
              <div className="boxTitleEIconBig">
                <FaUser size={20} color="#fff" />
                <p>Usuários por promoção</p>
              </div>

              <div className="boxAnaliseCenterBig">
                {Object.entries(usuariosPorPromocao)
                  .sort(
                    ([tituloA, quantidadeA], [tituloB, quantidadeB]) =>
                      quantidadeB - quantidadeA
                  )
                  .map(([tituloForm, quantidade]) => (
                    <div key={tituloForm} className="boxCidade">
                      <p>{tituloForm}</p>
                      <span>{quantidade}</span>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>

        <div className="coluna2">
          <div className="boxAnaliseBig blueBox">
            <div className="boxTitleEIconBig">
              <FaUser size={20} color="#fff" />
              <p>Usuários por Estado</p>
            </div>

            <div className="boxAnaliseCenterBig">
              {Object.entries(usuariosPorEstado)
                .sort(([estadoA], [estadoB]) => estadoA.localeCompare(estadoB))
                .map(([estado, quantidade]) => (
                  <div key={estado} className="boxCidade">
                    <p>{estado}</p>
                    <span>{quantidade}</span>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
