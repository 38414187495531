import React, { createContext, useState, useEffect } from "react";
import { auth, db } from "../services/conexaoFirebase";
import {
  authParceiros,
  dbParceiros,
} from "../services/conexaoFirebaseInvitePartners";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { collection, getDoc, getDocs, doc, setDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";

export const Context = createContext({});

export default function ContextProvider({ children }) {
  const [user, setUser] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  //console.log("userDetails: ", userDetails);
  const [loadingAuth, setLoadingAuth] = useState(false);
  const [loading, setLoading] = useState(true);
  const [usuarios, setUsuarios] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    async function loadUser() {
      const storageUser = localStorage.getItem("@usuarios");
      if (storageUser) {
        const decryptedData = CryptoJS.AES.decrypt(
          storageUser,
          "linkdobem5769"
        ).toString(CryptoJS.enc.Utf8);
        if (decryptedData) {
          const user = decryptedData; // Não é necessário chamar JSON.parse
          setUser(user);
          const userData = JSON.parse(decryptedData);
          setUserDetails(userData);

          navigate("/dash");
        } else {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    }

    loadUser();
  }, []);

  // buscando todos os usuários
  useEffect(() => {
    const fetchData = async () => {
      try {
        const usersCollection = collection(db, "users");
        const usersSnapshot = await getDocs(usersCollection);

        const usersData = usersSnapshot.docs.map((doc) => {
          const userData = {
            id: doc.id,
            ...doc.data(),
          };

          // Verifique se o campo 'formularios' está presente e preenchido
          if (!userData.formularios) {
            // Adicione lógica aqui para contar usuários que não preencheram o formulário
            // Por exemplo, você pode incrementar um contador no estado ou fazer o que for necessário
          }

          return userData;
        });

        setUsuarios(usersData);
      } catch (error) {
        toast.error("Erro ao obter usuários:", error);
      }
    };

    //fetchData();
  }, []);

  async function logar(email, senha) {
    setLoadingAuth(true);

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        senha
      );
      const user = userCredential.user;

      const docRef = doc(db, "admins", user.uid);
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        const userData = {
          uid: user.uid,
          email: user.email,
          tipoConta: docSnapshot.data().tipoConta,
          nomeParceiro: docSnapshot.data().nomeParceiro,
          categorias: docSnapshot.data().categorias || [],
        };

        setUser(userData);
        storageUser(userData);
        setLoadingAuth(false);
        toast.success("Bem-Vindo(a) de volta!");

        navigate("/dash");
      } else {
        toast.error("Apenas administradores podem fazer login.");
        setLoadingAuth(false);
      }
    } catch (error) {
      toast.error("Oops! Algo deu errado, " + error.message);
      console.error("Erro ao fazer login:", error);
      setLoadingAuth(false);
    }
  }

  // Função para cadastrar um novo usuário no Firestore
  async function cadastrarParceiro(email, password, nome) {
    try {
      // Crie um novo usuário com email e senha
      const { user } = await createUserWithEmailAndPassword(
        authParceiros,
        email,
        password
      );

      // Crie um documento com o UID do usuário na coleção 'users'
      await setDoc(doc(dbParceiros, "users", user.uid), {
        email: email,
        name: nome,
        status: true,
        dataCadastro: new Date().toLocaleDateString("pt-BR"),
        uid: user.uid,
      });

      toast.success("Parabéns, parceiro cadastrado!");

      // Retorne o UID do usuário para uso posterior
      return user.uid;
    } catch (error) {
      if (error.code === "auth/email-already-in-use") {
        toast.error(
          "O email fornecido já está em uso. Por favor, tente outro!"
        );
      } else if (error.code === "auth/weak-password") {
        toast.error(
          "A senha fornecida é fraca. Por favor, escolha uma senha mais forte."
        );
      } else if (error.code === "auth/invalid-email") {
        toast.error("O email fornecido não é válido.");
      } else {
        console.error("Erro ao cadastrar usuário:", error);
        toast.error("Erro ao cadastrar usuário:", error.message);
      }
      return null;
    } finally {
      return;
    }
  }

  function storageUser(data) {
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      "linkdobem5769"
    ).toString();
    localStorage.setItem("@usuarios", encryptedData);
  }

  async function logOut() {
    await signOut(auth)
      .then(() => {
        toast.success("Você foi desconectado com sucesso!");
        navigate("/");
      })
      .catch((error) => {
        toast.success(error);
      });
    localStorage.removeItem("@usuarios");
    setUser(null);
  }

  return (
    <Context.Provider
      value={{
        signed: !!user,
        user,
        userDetails,
        setUser,
        logar,
        logOut,
        loadingAuth,
        storageUser,
        usuarios,
        cadastrarParceiro,
      }}
    >
      {children}
    </Context.Provider>
  );
}
