import "../styles.css";
import React from "react";
import { Link } from "react-router-dom";
import { FiBell, FiActivity, FiUsers, FiSearch, FiSend } from "react-icons/fi";
import { ImSpinner6 } from "react-icons/im";

export default function Nivel3() {
  return (
    <div>
      <li>
        <ImSpinner6 size={22} color="#fff" />
        <Link to="/sorteio">Sorteios</Link>
      </li>
    </div>
  );
}
