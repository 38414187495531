import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { getStorage } from "firebase/storage";
//import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyA6f_57vfy0f_16Yo5iS2GF2XDfKSzmZkk",
  authDomain: "app-ib-6981f.firebaseapp.com",
  projectId: "app-ib-6981f",
  storageBucket: "app-ib-6981f.appspot.com",
  messagingSenderId: "589420770537",
  appId: "1:589420770537:web:97e314b3147ffe4db5ec78",
  measurementId: "G-CF2VWDCXV7",
};

const firebaseApp = initializeApp(firebaseConfig);

const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);
//const analytics = getAnalytics(app);

const getUsersCount = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, "users"));
    return querySnapshot.size;
  } catch (error) {
    console.error("Erro ao obter a lista de usuários:", error);
    return 0; // ou outra ação apropriada em caso de erro
  }
};

export { auth, db, storage, getUsersCount };
