import "../styles.css";
import React from "react";
import { Link } from "react-router-dom";
import { FiBell, FiActivity, FiUsers, FiSearch, FiSend } from "react-icons/fi";
import { ImSpinner6 } from "react-icons/im";

export default function Nivel2() {
  return (
    <div>
      <li>
        <FiBell size={22} color="#fff" />
        <Link to="/notification-control-center">Enviar notificações</Link>
      </li>

      <li>
        <FiSearch size={22} color="#fff" />
        <Link to="/buscar-pessoas">Buscar pessoas</Link>
      </li>
      <li>
        <FiUsers size={22} color="#fff" />
        <Link to="/cadastrar-conta">Cadastrar conta</Link>
      </li>
      <li>
        <FiSend size={22} color="#fff" />
        <Link to="/convidar">Convidar parceiros</Link>
      </li>
      <li>
        <ImSpinner6 size={22} color="#fff" />
        <Link to="/sorteio-geral">Sorteios</Link>
      </li>

      <li>
        <FiActivity size={22} color="#fff" />
        <Link to="/dash">Análises</Link>
      </li>
    </div>
  );
}
