import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FiActivity } from "react-icons/fi";
import { Context } from "../../Context/Auth";
import imagemParceiro from "../../assets/notPhoto.png";
import CryptoJS from "crypto-js";

export default function HeaderDashboardParceiros() {
  const { logOut } = useContext(Context);
  const [nomeParceiro, setNomeParceiro] = useState("");

  useEffect(() => {
    const usuariosData = localStorage.getItem("@usuarios");
    if (usuariosData) {
      const decryptedData = CryptoJS.AES.decrypt(
        usuariosData,
        "linkdobem5769"
      ).toString(CryptoJS.enc.Utf8);
      if (decryptedData) {
        const usuarios = JSON.parse(decryptedData);
        setNomeParceiro(usuarios.nomeParceiro);
      }
    }
  }, []);

  async function deslogar() {
    await logOut();
  }

  return (
    <div className="headerDashboard">
      <header>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 10,
            marginBottom: 30,
          }}
        >
          <div className="areaImagemPerfilPerceiro">
            <img src={imagemParceiro} alt="Foto do parceiro" />
          </div>
          <strong style={{ color: "#fff", fontSize: 16, textAlign: "center" }}>
            Olá, <br />
            <span style={{ fontWeight: "500" }}> {nomeParceiro}</span>!
          </strong>
        </div>

        <ul>
          {/* <li>
            <FiActivity size={22} color="#fff" />
            <Link to="/dash-analise-parceiros">Análises</Link>
          </li> */}
        </ul>
      </header>

      <div>
        <button className="buttonLogOut" onClick={deslogar}>
          Desconectar
        </button>
      </div>
    </div>
  );
}
